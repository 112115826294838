import React, { useCallback, useMemo } from 'react';
import { TextBox, Tooltip } from '../../../atoms';
import { MdWeekDate } from '../../../../api-client';
import { inventoryPlanTableDataStyle } from '../_components/styleUtil';

interface InputCellProps {
  mdWeekDate: MdWeekDate;
  wy: string;
  expectedSalesAmountByUser: number | null | undefined;
  pastedExpectedSalesAmountByUser: string | null;
  isDisabled: boolean;
  highlighted: boolean;
  isDemandAggregationTarget: boolean;
  idx: number;
  pastMdWeekDatesLength: number;
  onExpectedSalesAmountByUserChangeHandler: (
    mdWeekDate: MdWeekDate,
    quantity: number | null,
    hasError: boolean
  ) => void;
  onHighlightedColIndexChangeHandler: (index: number) => void;
  onBlurInputHandler: () => void;
  setPastedExpectedSalesAmountByUserList: React.Dispatch<React.SetStateAction<{ [key: string]: string | null }>>;
  presentAndFutureMdWeekDates: MdWeekDate[];
  productCode: string;
}

export const ExpectedSalesByUserCell: React.FC<InputCellProps> = React.memo((props) => {
  const {
    mdWeekDate,
    wy,
    expectedSalesAmountByUser,
    pastedExpectedSalesAmountByUser,
    isDisabled,
    highlighted,
    isDemandAggregationTarget,
    idx,
    pastMdWeekDatesLength,
    onExpectedSalesAmountByUserChangeHandler,
    onHighlightedColIndexChangeHandler,
    onBlurInputHandler,
    setPastedExpectedSalesAmountByUserList,
    presentAndFutureMdWeekDates,
    productCode,
  } = props;

  const handleChange = useCallback(
    (value: string, errorMessage: string) => {
      let quantity: number | null = Number.parseInt(value);
      if (Number.isNaN(quantity)) {
        quantity = null;
      }
      onExpectedSalesAmountByUserChangeHandler(
        mdWeekDate,
        quantity,
        errorMessage != null && errorMessage !== ''
      );
    },
    [mdWeekDate, onExpectedSalesAmountByUserChangeHandler]
  );

  const handlePaste = useCallback(
    (clipboardText: string) => {
      if (isDisabled) {
        return;
      }
      const multiRowsPasted = clipboardText.split('\r');
      // 複数行ある場合、一行目だけを使用
      const firstRowPasted = multiRowsPasted[0];
      const pastedColumns = firstRowPasted.split('\t');
      const pastableColumnsNumber = presentAndFutureMdWeekDates.length - idx;
      // 列数がオーバーしている場合は、切り捨てる
      const pasted = pastedColumns.slice(0, pastableColumnsNumber);
      const expectedSalesAmountByUserList = pasted.reduce(
        (prev, current, i) => {
          const wy = `${presentAndFutureMdWeekDates[idx + i].mdYear}-${presentAndFutureMdWeekDates[idx + i].mdWeekNum}`;
          return {
            ...prev,
            [wy]: current,
          };
        },
        {}
      );
      setPastedExpectedSalesAmountByUserList((prev) => ({
        ...prev,
        ...expectedSalesAmountByUserList,
      }));
      pasted.forEach((quantity, i) => {
        const mwd = presentAndFutureMdWeekDates[idx + i];
        onExpectedSalesAmountByUserChangeHandler(mwd, Number.parseInt(quantity), false);
      });
    },
    [
      isDisabled,
      idx,
      presentAndFutureMdWeekDates,
      setPastedExpectedSalesAmountByUserList,
      onExpectedSalesAmountByUserChangeHandler,
    ]
  );

  const handleClick = useCallback(() => {
    onHighlightedColIndexChangeHandler(pastMdWeekDatesLength + idx);
  }, [idx, onHighlightedColIndexChangeHandler, pastMdWeekDatesLength]);

  const cellStyle = useMemo(
    () =>
      inventoryPlanTableDataStyle(
        true,
        highlighted,
        false,
        isDemandAggregationTarget,
        false
      ),
    [highlighted, isDemandAggregationTarget]
  );

  return (
    <td
      className="inventory-plan-table-data"
      style={cellStyle}
      onClick={handleClick}
    >
      <div className="text-box-container">
        <TextBox
          id={`${productCode}-${wy}-expected-sales-amount-by-user-input`}
          type="number"
          width={77}
          height={22}
          padding={6}
          defaultValue={
            expectedSalesAmountByUser != null
              ? expectedSalesAmountByUser.toString()
              : ''
          }
          currentValue={
            pastedExpectedSalesAmountByUser != null
              ? pastedExpectedSalesAmountByUser.toString()
              : undefined
          }
          disabled={isDisabled}
          min={0}
          max={9999999}
          forceValidate={true}
          suppressErrorMessage={true}
          highlightOnChange={true}
          onChangeHandler={handleChange}
          onBlurHandler={onBlurInputHandler}
          onPasteHandler={handlePaste}
        />
        {expectedSalesAmountByUser != null &&
        (expectedSalesAmountByUser < 0 ||
          expectedSalesAmountByUser > 9999999) ? (
            <Tooltip
              text={'0~9,999,999以内の半角数字で入力してください。'}
              top={25}
              width={320}
            />
          ) : null}
      </div>
    </td>
  );
});
