
// 再レンダリングを防ぐために定数系を外に出す
export const inventoryPlanTableDataBgColor = (isbackgroundred: boolean, isbackgroundblue: boolean, ishighlight: boolean) => {
  if (isbackgroundred) {
    return '#f8d9d9';
  } else if (ishighlight) {
    return '#fafafa';
  } else if (isbackgroundblue) {
    return '#f5f9ff';
  } else {
    return '#fff';
  }
};

export const inventoryPlanTableDataBgColorStyle = (isbackgroundred: boolean, isbackgroundblue: boolean, ishighlight: boolean) => {
  return {
    backgroundColor: inventoryPlanTableDataBgColor(isbackgroundred, isbackgroundblue, ishighlight)
  };
};

export const inventoryPlanTableDataBoderBottomColor = (isboldbottom: boolean) => {
  return isboldbottom? '#707070' : 'var(--di-table-border-color)';
};

export const inventoryPlanTableDataColor = (iscolorred: boolean) => {
  return iscolorred? 'var(--di-text-error-color)' : 'var(--di-text-primary-color)';
};

export const inventoryPlanTableDataStyle = (isboldbottom: boolean, ishighlight: boolean, isbackgroundred: boolean, isbackgroundblue: boolean, iscolorred: boolean) => {
  return {
    backgroundColor: inventoryPlanTableDataBgColor(isbackgroundred,isbackgroundblue, ishighlight),
    borderBottomColor: inventoryPlanTableDataBoderBottomColor(isboldbottom),
    color: inventoryPlanTableDataColor(iscolorred)
  };
};