import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ReactComponent as CalendarIcon } from '../../../../src/assets/images/calendar-regular.svg';
import './TextBox.css';

/**
 * input 要素の種類. 命名はネイティブのものと合わせる.
 * スタイルの調整が必要なので用意ができている種類だけ有効値として型に定義する.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input}
 */
type TextBoxType = 'email' | 'number' | 'password' | 'text' | 'date';

export interface TextBoxProps {
  /**
   * いわゆる ID だが、以下の複数の用途として使われる:
   * - HTML 要素の id として使用
   * - label と対応する id として使用. id がなければラベルも表示されない
   * - react-testing-library 用の id として使用
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/id}
   */
  id?: string

  /**
   * ラベル. label だけ値を入れても id に値がなければ表示されない.
   */
  label?: string

  /**
   * 注記ラベル（入力値の制限など）
   */
  subLabel?: string

  /**
   * input 要素の種類.
   */
  type: TextBoxType;

  /**
   * 入力フォームの幅
   */
  width: number

  /**
   * 入力フォームの高さ
   */
  height: number

  /**
   * 入力フォームの内側の余白の幅
   */
  padding?: number;

  /**
   * フォームの初期値
   */
  defaultValue?: string;
  /**
    * フォームの現在値
    */
  currentValue?: string;

  /**
   * プレースホルダ
   */
  placeholder?: string;

  /**
   * オートコンプリート
   */
  autoComplete?: string;

  /**
   * 無効かどうか
   */
  disabled?: boolean;

  /**
   * 必須かどうか
   */
  required?: boolean;

  /**
   * 許容される最小文字数
   * text か password のみ適用される
   */
  minLength?: number;

  /**
   * 許容される最大文字数
   * text か password のみ適用される
   */
  maxLength?: number;

  /**
   * 許容される最小値
   * number か date のみ適用される
   */
  min?: number | string;

  /**
   * 許容される最大値
   * number か date のみ適用される
   */
  max?: number | string;

  /**
   * 初期表示にバリデーションを発火させるかどうか
   * 通常は入力フォームからフォーカスを外した場合にバリデーションが発火する
   */
  forceValidate?: boolean;

  /**
   * カスタムのエラーメッセージ
   * このコンポーネントで用意されていないエラーメッセージやバリデーションが必要な場合に使う
   */
  customErrorMessage?: string;

  /**
   * エラーメッセージを非表示にするかどうか
   * このコンポーネントで用意されているスタイルとは別にエラーメッセージを表示したい時に使う
   */
  suppressErrorMessage?: boolean;

  /**
   * 値が変更されたら背景色を変えるかどうか
   */
  highlightOnChange?: boolean;

  /**
   * type=dateの時のnumber
   */
  step?: number;

  /**
   * フォントファミリー
   */
  fontFamily?: string

  /**
   * fontSize
   */
  fontSize?: number;

  /**
   * 値が変更された時に呼び出されるハンドラー
   */
  onChangeHandler: (value: string, errorMessage: string) => void

  /**
   * フォーカスが外れた時に呼び出されるハンドラー
   */
  onBlurHandler?: (errorMessage: string) => void

  /**
   * ペーストされた時に呼び出されるハンドラー
   */
  onPasteHandler?: (pasteed: string) => void
}

const emailValidation = (value: string) => {
  // Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  const emailValidationRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", "");
  return !emailValidationRegex.test(value);
};

const getClassName = (isBackgroundYellow: boolean, hasErr: boolean, disabled: boolean, type: string) => {
  let className = 'base-input';
  if (isBackgroundYellow) {
    className += ' background-yellow';
  }
  if (hasErr) {
    className += ' has-err';
  }
  if (disabled) {
    className += ' disabled';
  }
  if (type === 'number') {
    className += ' type-number';
  }
  return className;
};

export const TextBox: React.FC<TextBoxProps> = ({
  id = '',
  label = '',
  subLabel = '',
  type,
  width,
  height,
  padding = 10,
  defaultValue = '',
  currentValue,
  placeholder = '',
  disabled = false,
  required = false,
  minLength = 0,
  maxLength,
  min,
  max,
  forceValidate = false,
  customErrorMessage = '',
  suppressErrorMessage = false,
  highlightOnChange = false,
  fontSize,
  fontFamily = 'monospace',
  onChangeHandler,
  onBlurHandler = () => {},
  onPasteHandler,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isInitialInputDone, setIsInitialInputDone] = useState(false);

  useEffect(()=>{
    if (currentValue) {
      setValue(() => currentValue);
    }
  },[currentValue]);

  useEffect(()=>{
    setValue(() => defaultValue);
  }, [defaultValue]);

  // TODO:: 2024-12 valueのsetは非同期なためerror判定が1回遅れています
  const errorMessage = useMemo(() => {
    if (!forceValidate && !isInitialInputDone) {
      return '';
    }
    if (required && value === '') {
      return '入力して下さい。';
    } else if (type === 'email' && emailValidation(value)) {
      return '不正な形式のメールアドレスです。';
    } else if ((type === 'password' || type ==='text') && minLength && value.length < minLength) {
      return `${minLength}文字以上で入力してください。`;
    } else if ((type === 'password' || type ==='text') && maxLength && value.length > maxLength) {
      return `${maxLength}文字以内で入力してください。`;
    } else if (type === 'number' && min != null && Number.parseInt(value) < min) {
      return `${min}以上で入力してください。`;
    } else if (type === 'number' && max != null && Number.parseInt(value) > max) {
      return `${max}以内で入力してください。`;
    } else {
      return '';
    }
  }, [required, type, minLength, maxLength, min, max, value, isInitialInputDone, forceValidate]);

  const calculateErrorMessage = (val: string) => {
    if (!forceValidate && !isInitialInputDone) {
      return '';
    }
    if (required && val === '') {
      return '入力して下さい。';
    } else if (type === 'email' && emailValidation(val)) {
      return '不正な形式のメールアドレスです。';
    } else if ((type === 'password' || type === 'text') && minLength && val.length < minLength) {
      return `${minLength}文字以上で入力してください。`;
    } else if ((type === 'password' || type === 'text') && maxLength && val.length > maxLength) {
      return `${maxLength}文字以内で入力してください。`;
    } else if (type === 'number' && min != null && Number.parseInt(val) < min) {
      return `${min}以上で入力してください。`;
    } else if (type === 'number' && max != null && Number.parseInt(val) > max) {
      return `${max}以内で入力してください。`;
    } else {
      return '';
    }
  };

  const hasError = useMemo(() => customErrorMessage !== '' || errorMessage !== '', [customErrorMessage, errorMessage]);
  const isBackgroundYellow = useMemo(() => highlightOnChange && value !== defaultValue, [highlightOnChange, value, defaultValue]);

  const baseInputStyle = useMemo(() => {
    return {
      fontSize: `${fontSize}px`,
      fontFamily: fontFamily,
      height: `${height}px`,
      paddingLeft:`${padding}px`,
      paddingRight: `${padding}px`,
      width: `${width}px`
    };
  }, [fontSize, fontFamily, height, padding, width]);

  const isDate = useMemo(() => type === 'date', [type]);
  const containerClassName = useMemo(() => {
    const baseClassName = 'base-input-container';
    if (isDate) {
      return baseClassName + ' type-date';
    }
    return baseClassName;
  }, [isDate]);

  return (
    <div className={containerClassName}>
      {id !== '' && label !== ''? (
        <label className="base-label" htmlFor={id}>
          {label}
          {subLabel !== ''? (
            <span className="base-sub-label">{subLabel}</span>
          ) : null}
        </label>
      ) : null}
      <input
        id={id}
        type={type}
        required={required}
        min={type === 'date' ? min : undefined}
        max={type === 'date' ? max : undefined}
        value={value}
        placeholder={placeholder}
        style={baseInputStyle}
        className={getClassName(isBackgroundYellow, hasError, disabled, type)}
        onChange={(event) => {
          if (disabled === true) {
            // jsdom may not have pointer event feature.
            // Ref: https://github.com/jsdom/jsdom/issues/2527
            return;
          }
          const newValue = event.target.value;
          if (!isInitialInputDone) {
            setIsInitialInputDone(() => true);
          }
          setValue(newValue);
          // errorMessageだとstateが更新される前の値が入るため、即時エラーメッセージを取得する
          const immediateErrorMessage = calculateErrorMessage(newValue);
          onChangeHandler(event.target.value, immediateErrorMessage);
        }}
        onBlur={() => {
          onBlurHandler(errorMessage);
        }}
        onPaste={(e) => {
          if (onPasteHandler) {
            onPasteHandler(e.clipboardData.getData('Text'));
            e.preventDefault();
          }
        }}
        data-testid={id}
        disabled={disabled}
        {...props}
      />
      { isDate ? <div className="calendar-icon"><CalendarIcon /></div> : null }
      {suppressErrorMessage === false?
        customErrorMessage !== ''?
          (
            <div className="error-message">
              {customErrorMessage}
            </div>
          ) : (
            <div className="error-message">
              {errorMessage}
            </div>
          ) : null}
    </div>
  );
};
