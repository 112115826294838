import { OrderGroup } from "../../../api-client";
import { ValidationError } from "./useValidationError";

// リードタイムの最大有効値は (52 - (サイクル回数 * サイクル週数)) * 7
export const calcMaxLeadtime = (
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number
) => (52 - numberOfOrderCycles * numberOfOrderCycleWeeks) * 7;

// サイクル回数の最大有効値は (52 - (リードタイム / 7)) / サイクル週数
// リードタイムが7で割り切れない場合は切り上げる
// サイクル週数で割って出た余りは切り捨てる（合計52を超えないようにする）
export const calcMaxNumberOfOrderCycles = (
  leadtime: number,
  numberOfOrderCycleWeeks: number
) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycleWeeks);

// サイクル週数の最大有効値は (52 - ((リードタイム / 7)) / サイクル回数
// リードタイムが7で割り切れない場合は切り上げる
// サイクル回数で割って出た余りは切り捨てる（合計52を超えないようにする）
export const calcMaxNumberOfOrderCycleWeeks = (
  leadtime: number,
  numberOfOrderCycles: number
) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycles);

const isValidLeadtime = (
  leadtime: number,
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number
) =>
  leadtime >= 1 &&
  leadtime <= calcMaxLeadtime(numberOfOrderCycles, numberOfOrderCycleWeeks);

const isValidNumberOfOrderCycles = (
  leadtime: number,
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number
) =>
  numberOfOrderCycles >= 1 &&
  numberOfOrderCycles <=
    calcMaxNumberOfOrderCycles(leadtime, numberOfOrderCycleWeeks);

const isValidNumberOfOrderCycleWeeks = (
  leadtime: number,
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number
) =>
  numberOfOrderCycleWeeks >= 1 &&
  numberOfOrderCycleWeeks <=
    calcMaxNumberOfOrderCycleWeeks(leadtime, numberOfOrderCycles);

export const hasValidationError = (
  simOrderGroup: OrderGroup | undefined,
  validationError: ValidationError
) =>
  (simOrderGroup != null &&
    !isValidLeadtime(
      simOrderGroup.leadtime,
      simOrderGroup.numberOfOrderCycles,
      simOrderGroup.numberOfOrderCycleWeeks
    )) ||
  (simOrderGroup != null &&
    !isValidNumberOfOrderCycles(
      simOrderGroup.leadtime,
      simOrderGroup.numberOfOrderCycles,
      simOrderGroup.numberOfOrderCycleWeeks
    )) ||
  (simOrderGroup != null &&
    !isValidNumberOfOrderCycleWeeks(
      simOrderGroup.leadtime,
      simOrderGroup.numberOfOrderCycles,
      simOrderGroup.numberOfOrderCycleWeeks
    )) ||
  Object.values(validationError).some((product) =>
    Object.values(product.inventoryPlanPerMdWeek).some((inventoryPlan) =>
      Object.values(inventoryPlan).some((hasError) => hasError)
    )
  );
