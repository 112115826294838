import { useCallback, useEffect, useState } from "react";
import { OrderDetail } from "../../../api-client";

export type ValidationError = {
  [productCode: string]: {
    inventoryPlanPerMdWeek: ValidationErrorInventoryPlanPerMdWeek
  };
};

type ValidationErrorInventoryPlanPerMdWeek = {
  [mdWeekYearNumStr: string]: {
    shipmentQuantity: boolean
    confirmedOrderQuantity: boolean
    storeInventoryQuantityStandard: boolean
    expectedSalesAmountByUser: boolean
  }
};

type Props = {
  simOrderDetail: OrderDetail | undefined;
};

/**
 * validationErrorのstateを管理するhooks
 * @returns 
 */
export function useValidationError(props: Props) {
  const [validationError, setValidationError] = useState<ValidationError>({});

  // 入力変更時にvalidationをかける(が目的だと思う。本来は副作用でななくイベントに仕込ませるべき)
  const initializeValidationError = useCallback(() => {
    if (props.simOrderDetail == null) {
      return;
    }
    const newValidationError: ValidationError = {};
    props.simOrderDetail.products.forEach((product) => {
      const inventoryPlanPerMdWeek: ValidationErrorInventoryPlanPerMdWeek = {};
      Object.keys(product.inventoryPlanPerMdWeek).forEach((mdWeek) => {
        inventoryPlanPerMdWeek[mdWeek] = {
          shipmentQuantity: false,
          confirmedOrderQuantity: false,
          storeInventoryQuantityStandard: false,
          expectedSalesAmountByUser: false,
        };
      });
      newValidationError[product.code] = {
        inventoryPlanPerMdWeek
      };
    });
    setValidationError(newValidationError);
  }, [props.simOrderDetail]);

  // 初期化処理の実行
  useEffect(() => {
    if (props.simOrderDetail && Object.keys(validationError).length === 0) {
      initializeValidationError();
    }
  }, [initializeValidationError]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateValidationErrorField = useCallback((
    productCode: string,
    wy: string,
    field: keyof ValidationError[string]['inventoryPlanPerMdWeek'][string],
    hasError: boolean
  ) => {
    setValidationError(currentError => {
      const updatedError = { ...currentError };
      if (!updatedError[productCode]) {
        updatedError[productCode] = { inventoryPlanPerMdWeek: {} };
      }
      if (!updatedError[productCode].inventoryPlanPerMdWeek[wy]) {
        updatedError[productCode].inventoryPlanPerMdWeek[wy] = {} as ValidationError[string]['inventoryPlanPerMdWeek'][string];
      }
      updatedError[productCode].inventoryPlanPerMdWeek[wy][field] = hasError;
      return updatedError;
    });
  }, []);

  return { 
    validationError, 
    updateValidationErrorField
  };
}