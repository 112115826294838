import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { User, UserRole } from "../../api-client";

import { useDestocking } from "../../hooks";

import {
  Button,
  CheckBox,
  ErrorMessagePanel,
  LoadingSpinner,
  OrderSummaryTable,
  PageHeader,
  TextBox,
  Toast,
  Tooltip,
} from "../../components/atoms";

import {
  OrderConfirmationModal,
  StopAdjustmentAlertModal,
} from "../../components/molecules";

import { ProductPanel } from "../../components/organisms";

import {
  calcMaxLeadtime,
  calcMaxNumberOfOrderCycles,
  calcMaxNumberOfOrderCycleWeeks,
  hasValidationError,
} from "./hooks/validationUtil";
import {
  BackToTopLinkContainer,
  ButtonsContainer,
  ContentsContainer,
  ContentsContainerOuter,
  EmptyOrderSummaryMessage,
  EmptySKUsMessage,
  ErrorMessagePanelContainer,
  LeadtimeFormLabel,
  LeadtimeFormsContainer,
  LoadingSpinnerContainer,
  OrderButtonContainer,
  OrderSummaryTableContainer,
  ProductPanelContainer,
  ProductPanelContainerOuter,
  RecalculatingMessage,
  RecalculatingMessageContainer,
  SectionName,
  TextBoxContainer,
  ToastContainer,
} from "./style";

interface DestockingPathParams {
  orderGroupId: string;
}

export interface DestockingProps {
  user: User;
}

export const Destocking: React.FC<DestockingProps> = ({ user }) => {
  const { orderGroupId } = useParams<DestockingPathParams>();
  const orderGroupIdNum = Number.parseInt(orderGroupId);

  const {
    orderGroup: originalOrderGroup,
    orderDetail,
    orderSummary: originalOrderSummary,
    simOrderGroupState,
    statusCode,
    putDestockingOrder,
    getCSVFile,
    putDestocking,
    putDestockingOrderGroupIncomingMdWeekBackward,
    putDestockingOrderGroupIncomingMdWeekForward,
    getDestocking,
    updateSkuMeta,
    hasChange,
    recalculationMessageVisible,
    setRecalculationMessageVisible,
    validationError,
    hasUnsavedChange,
    handleBlur,
    handleClickSubCategoryPredictionCheckbox,
    lastEditedNCycleParameter,
    handleNumberOfOrderCyclesChange,
    handleNumberOfOrderCycleWeeksChange,
    handleLeadtimeChange,
    handleShortageToleranceRankChange,
    handleShipmentReasonChange,
    handleExpectedSalesAmountByUserChange,
    handleShipmentQuantityChangeHandler,
    handleConfirmedOrderQuantityChangeHandler,
    handleStoreInventoryQuantityStandardChangeHandler,
  } = useDestocking(orderGroupIdNum);

  const orderGroup = simOrderGroupState.simOrderGroup;

  const [
    orderConfirmationModalVisible,
    setOrderConfirmationModalVisible,
  ] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [
    stopAdjustmentAlertModalVisible,
    setStopAdjustmentAlertModalVisible,
  ] = useState(false);
  const [adjustmentAlertDate, setAdjustmentAlertDate] = useState("");
  const [adjustmentAlertProduceCode, setAdjustmentAlertProduceCode] = useState(
    ""
  );

  const openOrderConfirmationModal = () => {
    if (hasChange === true) {
      return;
    }
    setOrderConfirmationModalVisible(true);
  };

  const memorizedPresentAndFutureMdWeekDates = useMemo(() => 
    orderDetail?.presentAndFutureMdWeekDates ?? []
  , [orderDetail?.presentAndFutureMdWeekDates]);
  
  const memorizedPastMdWeekDates = useMemo(() => 
    orderDetail?.pastMdWeekDates ?? []
  , [orderDetail?.pastMdWeekDates]);

  
  // 管理者 or 更新中のメッセージ表示中の場合はボタンを無効化
  const isDisabled = useMemo(() => {
    return (
      user.role === UserRole.Manager || recalculationMessageVisible === true
    );
  }, [user.role, recalculationMessageVisible]);

  if (statusCode == null) {
    return (
      <div style={{marginTop: 10}}>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </div>
    );
  }

  if (statusCode != null && statusCode !== 200) {
    return (
      <ErrorMessagePanelContainer>
        <ErrorMessagePanel statusCode={statusCode} />
      </ErrorMessagePanelContainer>
    );
  }

  return (
    <>
      {stopAdjustmentAlertModalVisible === true ? (
        <StopAdjustmentAlertModal
          disabled={hasValidationError(orderGroup, validationError)}
          onSubmitHandler={async () => {
            if (orderDetail == null) {
              return;
            }
            const productIndex = orderDetail.products.findIndex(
              ({ code }) => code === adjustmentAlertProduceCode
            );

            const updatedOrderDetail = {
              ...orderDetail,
              products: orderDetail.products.map((product, idx) => {
                if (idx !== productIndex) return product;
                return {
                  ...product,
                  inventoryPlanPerMdWeek: {
                    ...product.inventoryPlanPerMdWeek,
                    [adjustmentAlertDate]: {
                      ...product.inventoryPlanPerMdWeek[adjustmentAlertDate],
                      isAdjustmentAlertStopped: true
                    }
                  }
                };
              })};
            if ( originalOrderGroup != null && orderDetail != null && hasValidationError(orderGroup, validationError) === false) {
              setRecalculationMessageVisible(true);
              try {
                await putDestocking(
                  originalOrderGroup,
                  updatedOrderDetail
                );
                setStopAdjustmentAlertModalVisible(false);
              } finally {
                setRecalculationMessageVisible(false);
              }
            }}
          }
          onCloseHandler={() => {
            setStopAdjustmentAlertModalVisible(false);
          }}
        />
      ) : null}
      <ContentsContainerOuter>
        <PageHeader
          pageTitle={
            orderGroup != null
              ? `${orderGroup.name}（${
                orderGroup.supplierName != null
                  ? orderGroup.supplierName
                  : "-"
              }）`
              : ""
          }
          backLink={true}
        />
        {toastVisible === true ? (
          <ToastContainer>
            <Toast
              message="発注登録しました。"
              onCloseHandler={() =>
                setTimeout(() => setToastVisible(false), 300)
              }
            />
          </ToastContainer>
        ) : null}
        <ButtonsContainer>
          <React.Fragment>
            <Button
              styleType="secondary"
              label="ダウンロード"
              width={130}
              disabled={recalculationMessageVisible === true}
              onClickHandler={() => {
                getCSVFile(orderGroupIdNum)?.then(({ filename, blob }) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    filename != null ? filename : ""
                  );
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode?.removeChild(link);
                });
              }}
              data-testid="csv-download-button"
            />
            {user.role === UserRole.Operator ? (
              <OrderButtonContainer>
                <Button
                  styleType="primary"
                  label="発注"
                  width={76}
                  disabled={
                    orderGroup == null ||
                    orderDetail == null ||
                    hasUnsavedChange
                  }
                  onClickHandler={() => openOrderConfirmationModal()}
                  data-testid="open-order-confirmation-modal-button"
                />
                {orderGroup != null &&
                orderConfirmationModalVisible === true ? (
                    <OrderConfirmationModal
                      onCloseHandler={() =>
                        setOrderConfirmationModalVisible(false)
                      }
                      onSubmitHandler={() => {
                        if (orderGroup == null) {
                          return;
                        }
                        putDestockingOrder(orderGroup)?.then(() =>
                          setToastVisible(true)
                        );
                        setOrderConfirmationModalVisible(false);
                      }}
                    />
                  ) : null}
              </OrderButtonContainer>
            ) : null}
          </React.Fragment>
        </ButtonsContainer>
        <LeadtimeFormsContainer>
          {orderGroup != null && orderDetail ? (
            <React.Fragment>
              <LeadtimeFormLabel>サブカテゴリ予測：</LeadtimeFormLabel>
              <TextBoxContainer>
                <CheckBox
                  id="subcategory-prediction-checkbox"
                  size={35}
                  defaultValue={originalOrderGroup?.useSubcategoryPrediction}
                  disabled={isDisabled}
                  onChangeHandler={handleClickSubCategoryPredictionCheckbox}
                  setUncheckedMarkColor={true}
                />
              </TextBoxContainer>
              <LeadtimeFormLabel>リードタイム(日)：</LeadtimeFormLabel>
              <TextBoxContainer>
                <TextBox
                  id="leadtime-input"
                  type="number"
                  width={55}
                  height={40}
                  defaultValue={
                    originalOrderGroup != null
                      ? originalOrderGroup.leadtime.toString()
                      : ""
                  }
                  disabled={isDisabled}
                  required={true}
                  min={1}
                  max={calcMaxLeadtime(
                    orderGroup.numberOfOrderCycles,
                    orderGroup.numberOfOrderCycleWeeks
                  )}
                  forceValidate={true}
                  suppressErrorMessage={true}
                  highlightOnChange={true}
                  onChangeHandler={handleLeadtimeChange}
                  onBlurHandler={handleBlur}
                />
                {orderGroup != null &&
                (orderGroup.leadtime < 1 ||
                  (lastEditedNCycleParameter === "leadtime" &&
                    orderGroup.leadtime >
                      calcMaxLeadtime(
                        orderGroup.numberOfOrderCycles,
                        orderGroup.numberOfOrderCycleWeeks
                      ))) ? (
                    <Tooltip
                      id="leadtime-validation-error-tooltip"
                      text="合計52週の半角数字で入力して下さい。"
                      top={45}
                      width={262}
                    />
                  ) : null}
              </TextBoxContainer>
              <LeadtimeFormLabel>サイクル回数(週)：</LeadtimeFormLabel>
              <TextBoxContainer>
                <TextBox
                  id="number-of-order-cycles-input"
                  type="number"
                  width={55}
                  height={40}
                  defaultValue={
                    originalOrderGroup != null
                      ? originalOrderGroup.numberOfOrderCycles.toString()
                      : ""
                  }
                  disabled={isDisabled}
                  required={true}
                  min={1}
                  max={calcMaxNumberOfOrderCycles(
                    orderGroup.leadtime,
                    orderGroup.numberOfOrderCycleWeeks
                  )}
                  suppressErrorMessage={true}
                  forceValidate={true}
                  highlightOnChange={true}
                  onChangeHandler={handleNumberOfOrderCyclesChange}
                  onBlurHandler={handleBlur}
                />
                {orderGroup != null &&
                (orderGroup.numberOfOrderCycles < 1 ||
                  (lastEditedNCycleParameter === "number-of-order-cycles" &&
                    orderGroup.numberOfOrderCycles >
                      calcMaxNumberOfOrderCycles(
                        orderGroup.leadtime,
                        orderGroup.numberOfOrderCycleWeeks
                      ))) ? (
                    <Tooltip
                      id="number-of-order-cycles-validation-error-tooltip"
                      text="合計52週の半角数字で入力して下さい。"
                      top={45}
                      width={262}
                    />
                  ) : null}
              </TextBoxContainer>
              <LeadtimeFormLabel>サイクル週数(週)：</LeadtimeFormLabel>
              <TextBoxContainer>
                <TextBox
                  id="number-of-order-cycle-weeks-input"
                  type="number"
                  height={40}
                  width={55}
                  defaultValue={
                    originalOrderGroup != null
                      ? originalOrderGroup.numberOfOrderCycleWeeks.toString()
                      : ""
                  }
                  disabled={isDisabled}
                  required={true}
                  min={1}
                  max={calcMaxNumberOfOrderCycleWeeks(
                    orderGroup.leadtime,
                    orderGroup.numberOfOrderCycles
                  )}
                  suppressErrorMessage={true}
                  forceValidate={true}
                  highlightOnChange={true}
                  onChangeHandler={handleNumberOfOrderCycleWeeksChange}
                  onBlurHandler={handleBlur}
                />
                {orderGroup != null &&
                (orderGroup.numberOfOrderCycleWeeks < 1 ||
                  (lastEditedNCycleParameter ===
                    "number-of-order-cycle-weeks" &&
                    orderGroup.numberOfOrderCycleWeeks >
                      calcMaxNumberOfOrderCycleWeeks(
                        orderGroup.leadtime,
                        orderGroup.numberOfOrderCycles
                      ))) ? (
                    <Tooltip
                      id="number-of-order-cycle-weeks-validation-error-tooltip"
                      text="合計52週の半角数字で入力して下さい。"
                      top={45}
                      width={262}
                    />
                  ) : null}
              </TextBoxContainer>
            </React.Fragment>
          ) : null}
        </LeadtimeFormsContainer>
        <ContentsContainer>
          <SectionName>発注サマリー</SectionName>
          {originalOrderSummary != null ? (
            originalOrderSummary.orderMdWeekDates.length === 0 ? (
              <EmptyOrderSummaryMessage>
                入庫予定がありません。
              </EmptyOrderSummaryMessage>
            ) : (
              <OrderSummaryTableContainer>
                <OrderSummaryTable
                  orderSummary={originalOrderSummary}
                  isIncomingWeekUpdatable={
                    user.role !== UserRole.Manager &&
                    !hasValidationError(orderGroup, validationError) &&
                    !recalculationMessageVisible
                  }
                  onUpdateIncomingMdWeekBackward={(
                    mdWeek: number,
                    mdYear: number,
                    date: Date
                  ) => {
                    if (originalOrderGroup != null) {
                      setRecalculationMessageVisible(true);
                      putDestockingOrderGroupIncomingMdWeekBackward(
                        originalOrderGroup,
                        mdWeek,
                        mdYear,
                        date
                      )?.then(() => {
                        setRecalculationMessageVisible(false);
                      });
                    }
                  }}
                  onUpdateIncomingMdWeekForward={(
                    mdWeek: number,
                    mdYear: number,
                    date: Date
                  ) => {
                    if (originalOrderGroup != null) {
                      setRecalculationMessageVisible(true);
                      putDestockingOrderGroupIncomingMdWeekForward(
                        originalOrderGroup,
                        mdWeek,
                        mdYear,
                        date
                      )?.then(() => {
                        setRecalculationMessageVisible(false);
                      });
                    }
                  }}
                />
              </OrderSummaryTableContainer>
            )
          ) : (
            <LoadingSpinnerContainer>
              <LoadingSpinner />
            </LoadingSpinnerContainer>
          )}
          <SectionName>SKU一覧</SectionName>
          {orderGroup != null && orderDetail != null ? (
            <>
              {orderDetail.products.length === 0 ? (
                <EmptySKUsMessage>SKUが登録されていません。</EmptySKUsMessage>
              ) : (
                orderDetail.products.map((product, index) => {
                  if (orderGroup == null || orderDetail == null) {
                    return null;
                  }
                  return (
                    <ProductPanelContainerOuter key={index}>
                      <ProductPanelContainer
                        id={`product-panel-${product.code}`}
                      >
                        <ProductPanel
                          product={product}
                          pastMdWeekDates={memorizedPastMdWeekDates}
                          presentAndFutureMdWeekDates={memorizedPresentAndFutureMdWeekDates}
                          readonly={isDisabled}
                          isStoppableAdjustmentAlert={
                            !hasValidationError(orderGroup, validationError)
                          }
                          orderGroupId={originalOrderGroup?.id ?? 0}
                          onShortageToleranceRankChangeHandler={(value) => handleShortageToleranceRankChange(index, value)}
                          onShipmentReasonChangeHandler={(
                            mdWeekDate,
                            shipmentReason
                          ) => handleShipmentReasonChange(index, mdWeekDate, shipmentReason)}
                          onShipmentQuantityChangeHandler={
                            handleShipmentQuantityChangeHandler
                          }
                          onConfirmedOrderQuantityChangeHandler={
                            handleConfirmedOrderQuantityChangeHandler
                          }
                          onStoreInventoryQuantityStandardChangeHandler={
                            handleStoreInventoryQuantityStandardChangeHandler
                          }
                          onBlurInputHandler={handleBlur}
                          onClickStopAdjustmentAlertHandler={(
                            date: string,
                            productCode: string
                          ) => {
                            setStopAdjustmentAlertModalVisible(true);
                            setAdjustmentAlertDate(date);
                            setAdjustmentAlertProduceCode(productCode);
                          }}
                          getDestocking={getDestocking}
                          updateSkuMeta={updateSkuMeta}
                          index={index}
                          onExpectedSalesAmountByUserChangeHandler={
                            handleExpectedSalesAmountByUserChange
                          }
                        />
                        <BackToTopLinkContainer
                          onClick={() => {
                            // jsdom may not have scrollTo method. Ref: https://github.com/jsdom/jsdom/issues/1422
                            if (window.scrollTo != null) {
                              window.scrollTo(0, 0);
                            }
                          }}
                          data-testid="destocking-back-to-top-link"
                        >
                          ページTOPに戻る
                        </BackToTopLinkContainer>
                      </ProductPanelContainer>
                    </ProductPanelContainerOuter>
                  );
                })
              )}
            </>
          ) : (
            <LoadingSpinnerContainer>
              <LoadingSpinner />
            </LoadingSpinnerContainer>
          )}
        </ContentsContainer>
        {recalculationMessageVisible === true ? (
          <RecalculatingMessageContainer>
            <RecalculatingMessage>処理中</RecalculatingMessage>
          </RecalculatingMessageContainer>
        ) : null}
      </ContentsContainerOuter>
    </>
  );
};
